import React, {useState, useRef, useEffect, useImperativeHandle} from "react"
import isMobile from '../../utils/isMobile';
import Lottie from 'lottie-react-web'

import '../../assets/scss/header.scss'
import logo from '../../assets/images/logo-1217.svg'
import audioIco from '../../assets/json/audio.json'

// mp3采样率太高，在sfari中不能播放，目前调整为44.1KH
import audioSource from '../../assets/video/bgm2.mp3';

const Header = (props) => {

  const [isPaused, setPaused] = useState(true);
  const audio = useRef(null);

  let togglePlayStatus = (e, handControl) => {
    e.stopPropagation && e.stopPropagation();
    if (handControl !== undefined) {
      if (handControl) {
        audio.current.play();
        setPaused(false);
      } else {
        audio.current.pause();
        setPaused(true);
      }
      return;
    }

    if (isPaused) {
      audio.current.play();
      setPaused(false);
    } else {
      audio.current.pause();
      setPaused(true);
    }
  }

  useEffect(() => {

    // 不能自动播放的，首次点击页面开启播放
    let fun = null;
    window.addEventListener('click', fun = () => {
      togglePlayStatus({} , true);
      window.removeEventListener('click', fun)
    })

    // 可以自动播放的，切换动画状态
    audio.current.onplaying = () => {
      togglePlayStatus({} , true);
      window.removeEventListener('click', fun);
      audio.current.onplaying = null;
    }

    if (isMobile) {
      setPaused(false);
      setTimeout(() => {
        setPaused(true);
      }, 0)
    }


  }, [])

  useImperativeHandle(props.cRef, () => ({
    togglePlayStatus
  }))

  return(
    <div className="header-wrap">
      <div>
        <img src={logo} className="logo" alt="" />

        <div className="play-wrap" onClick={togglePlayStatus}>
          <div className={'play-circle' + ' ' + (isPaused ? 'stop' : '')}></div>
          <div className="audio-line">
            <Lottie options={{animationData: audioIco}} isPaused={isPaused} />
          </div>
        </div>

        <audio src={audioSource} autoPlay loop ref={audio}></audio>

      </div>
    </div>
  )
}

export default Header