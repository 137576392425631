import React, { useState, useEffect } from "react";
import Bottom from '../common/Bottom';
import Lottie from 'lottie-react-web'
import isMobile from '../../utils/isMobile';
import 'animate.css';
import './index.scss'

import spaceManImg from '../../assets/images/page5/space-man.png'
import bgGifDefault from '../../assets/images/page5/bg5.gif';
import bgMobile from '../../assets/images/page5/bg5-mobile.jpg'
import leftImg from '../../assets/images/page5/left-img.png'
// import bang from '../../assets/json/bang.json'
import bangImg from '../../assets/images/page5/bang.png'

const Page5 = (props) => {

  const [bgGif, setBgGif] = useState(null);

  useEffect(() => {
    if (props.show) {
      setBgGif(bgGifDefault + `?${parseInt(Math.random() * 100000)}`);
    } else {
      setBgGif(null);
    }
  }, [props]);

  let {show, showVideoModal} = props;

  return (
    <div className="page5-wrap">

      {
        show &&
        <>
          {
            // bgGif && <img src={bgGif} alt='' className='bg-img' />
          }

          <div className='bang-box'>
            {/* <Lottie options={{animationData: bang, loop: false}} /> */}
            <img src={bangImg} alt='' className="bang-img animate__animated animate__fadeIn animate__slow animate__delay-1s" />
          </div>

          <img src={spaceManImg} className='space-man5' alt='' />

          <div className="content">
            <div className="p1 animate__animated animate__fadeInDown animate__faster animate__delay-100ms">
              bring metaverse to universe
            </div>
            <div className="p2">
              <div className="t0 animate__animated animate__fadeInDown animate__faster animate__delay-200ms">
                “Humanity's common mission is space exploration, and the "Sea of Stars" is humanity's future.”
              </div>
              <div className="t1 animate__animated animate__fadeInDown animate__faster animate__delay-300ms">
                - TRON/BTTC
              </div>
              <div className="t2 animate__animated animate__fadeInDown animate__faster animate__delay-400ms">
                Justin Sun has made the route of unknown exploration of the cosmos and metaverse even more brilliant, from the earliest proponents of blockchain to the first proponents of commercial aerospace.
                <br></br>
                TRON aims to develop a complete blockchain ecosystem and the next generation of global internet and financial infrastructures. TRON 's cross-chain extension solution BTTC was officially launched on October 30 at the same time. It will be interoperable with other public chains in the future and enable the "ten thousand chain connection". TRON has gradually become an important infrastructure of the "Metaverse", breaking down the boundaries between virtual and real worlds and bringing the imagination of former users back to real time and space.
              </div>
              <div className="t1 animate__animated animate__fadeInDown animate__faster animate__delay-500ms">
                - FUTURE
              </div>
              <div className="t2 animate__animated animate__fadeInDown animate__faster animate__delay-600ms">
              At the same time, as aerospace technology advances, humanity is exploring a wider and greater space for more and more people to participate in and enjoy, expanding the scope of human activity. The clash of the two realities, the reality of the universe and the digital metaverse, will further enrich the future of humanity.
              </div>
            </div>
          </div>

          <Bottom leftImg={leftImg} leftTxt='Impact' showVideoModal={showVideoModal} p={5} />
        </>
      }
    </div>
  )
}

const Page5Mobile = (props) => {

  return (
    <div className='page-mobile'>

      <img src={bgMobile} alt='' className='bg-img' />

      <div className='txt-wrap'>
        <div className="title">bring metaverse to universe</div>
        <div className="cont1">“Humanity's common mission is space exploration, and the "Sea of Stars" is humanity's future.”</div>
        <div className="sub">- TRON/BTTC</div>
        <div className="cont">
          Justin Sun has made the route of unknown exploration of the cosmos and metaverse even more brilliant, from the earliest proponents of blockchain to the first proponents of commercial aerospace.
          <br></br>
          TRON aims to develop a complete blockchain ecosystem and the next generation of global internet and financial infrastructures. TRON 's cross-chain extension solution BTTC was officially launched on October 30 at the same time. It will be interoperable with other public chains in the future and enable the "ten thousand chain connection". TRON has gradually become an important infrastructure of the "Metaverse", breaking down the boundaries between virtual and real worlds and bringing the imagination of former users back to real time and space.
        </div>
        <div className="sub">- FUTURE </div>
        <div className="cont">At the same time, as aerospace technology advances, humanity is exploring a wider and greater space for more and more people to participate in and enjoy, expanding the scope of human activity. The clash of the two realities, the reality of the universe and the digital metaverse, will further enrich the future of humanity. </div>
      </div>

    </div>
  )
}

export default isMobile ? Page5Mobile : Page5