import React, { useState } from "react";
import isMobile from '../../utils/isMobile';
import 'animate.css';
import './index.scss'


//import backImg from '../../assets/images/page7/back-to-top-1.png'
//import back2Img from '../../assets/images/page7/back-to-top-2.png'
import logo2Img from '../../assets/images/page7/logo-lb2.png'
// import bgMobile from '../../assets/images/page7/bg7-mobile-new.png'
// import list1 from '../../assets/images/page7/soth.png'
// import list2 from '../../assets/images/page7/nift.png'
// import list3 from '../../assets/images/page7/chri.png'
// import list4 from '../../assets/images/page7/bitebi.png'
// import list5 from '../../assets/images/page7/eth.png'
// import list6 from '../../assets/images/page7/helo.png'
// import list7 from '../../assets/images/page7/bian.png'
// import list8 from '../../assets/images/page7/heco.png'
//import poiImg from '../../assets/images/page7/back-to-top-2.png';
import backTopPc from '../../assets/images/page7/back_top_pc.svg';
import backTop from '../../assets/images/page7/back_top.svg';
import arrow from '../../assets/images/page7/arrow.svg';
import arrowLight from '../../assets/images/page7/arrow_hover.svg';

const links = [
  { title: "The Economist On Justin Sun’s Entry Into Space Sector: Conducive To Promotion Of Space Tourism.", link: "https://klexplore.com/news/the-economist-on-justin-suns-entry-into-space-sector-conducive-to-promotion-of-space-tourism/0353233" },
  { title: "Along with Blue Origin Justin Sun is Ready to Explore the Vast Universe.", link: "https://www.ibtimes.sg/along-blue-origin-justin-sun-ready-explore-vast-universe-62036" },
  { title: "Justin Sun Received Six Tickets to New Shepherd Rocket Of Blue Origin And Inviting More People to Explore the Universe.", link: "https://www.marketwatch.com/press-release/justin-sun-received-six-tickets-to-new-shepherd-rocket-of-blue-origin-and-inviting-more-people-to-explore-the-universe-2021-12-23?tesla=y" },
  { title: "Justin Sun is Taking Five Additional Crewmates with him Through the “Sea of Stars” Campaign.", link: "https://londonjournal.co.uk/2021/12/22/justin-sun-is-taking-five-additional-crewmates-with-him-through-the-sea-of-stars-campaign/" }
]

const linkWebsite = (link) => {
  window.open(link)
}

const Page7 = (props) => {

  let {show, swiperRef} = props;
  const [active, setActive] = useState(0)
  const addActive = (index) => {
    setActive(index)
  }

  return (
    <div className="page7-wrap">
      {
        show &&
        <>
          <div className="content">
            <div className="title">
              <div className="p1 animate__animated animate__fadeInDown  animate__faster animate__delay-100ms">
                information
              </div>
              <div className="p2">
                <div className="back">
                  <span className="animate__animated animate__fadeInDown animate__fast animate__delay-300ms" onClick={() => {swiperRef.slideTo(0)}}>Back to top</span>
                  <img className="img1 animate__animated animate__fadeInDown animate__fast animate__delay-500ms" src={backTopPc} alt="." onClick={() => {swiperRef.slideTo(0)}} />
                </div>
              </div>
            </div>
            <div className="information">
              {
                links.map((item, index) => {
                  return <div className={`information-item animate__animated animate__fadeInDownBig delay${index+1}`} onMouseEnter={ () => addActive(index+1) } onMouseOver={ () => addActive(index+1) } onMouseOut={ () => addActive(0) } onClick={() => linkWebsite(item.link)}>
                    <span className="num">{`0${index+1}`}</span>
                    <p className={`animate__animated animate__fadeIn delay${index+1}p`}>{item.title}</p>
                    {  
                      active === index+1 ? <img className="arrow" src={arrowLight}></img> : <img className="arrow" src={arrow}></img>
                    }
                    <div className="layout" style={{ display: active === index+1 ? 'block' : 'none' }}></div>
                  </div>
                })
              }
            </div>
          </div>
          <div className="footer animate__animated animate__fadeIn animate__fast animate__delay-100ms">
            <div className="logo-lb">
              <img src={logo2Img} alt="." />
            </div>
            <div className="cp-right">
              <div className="t1">COPYRIGHT© 2017-2021 TRON FOUNDATION</div>
              {/* <div className="t1 t2">Beijing ICP No. 11017824-4</div>
              <div className="t1 t2">Copyright © 2006-2021  Sea Of Stars</div> */}
            </div>
          </div>
        </>
      }
    </div>
  )
}

const Page7Mobile = (props) => {

  return (
    <div className='page-mobile page7-mobile'>

     {/*} <img src={bgMobile} alt='' className='bg-img' />*/}

      <div className='txt-wrap'>
        <div className="title">information</div>
        <div className="infor-list">
          {
             links.map((item, index) => {
              return <div className="infor-item" onClick={ () => linkWebsite(item.link) } key={index}>
              <span className="num">{`0${index + 1}`}</span>
              <span className="subtitle">{ item.title }</span>
              <div className="layout"></div>
            </div>
            })
          }
        </div>
        {/* <table className='logo-list'>
          <tbody>
            <tr>
              <td><img src={list1} alt="." className='logo1' /></td>
              <td><img src={list2} alt="." className='logo2' /></td>
              <td><img src={list3} alt="." className='logo3' /></td>
            </tr>
            <tr>
              <td><img src={list4} alt="." className='logo4' /></td>
              <td><img src={list5} alt="." className='logo5' /></td>
              <td><img src={list6} alt="." className='logo6' /></td>
            </tr>
            <tr>
              <td><img src={list7} alt="." className='logo7' /></td>
              <td><img src={list8} alt="." className='logo8' /></td>
            </tr>
          </tbody>
        </table> */}
        <div className='back-top' onClick={() => {document.documentElement.scrollTop = 0;document.body.scrollTop = 0;}}>
          <div className='back-txt'>Back to top</div>
      
          <img src={backTop} alt='' className="poi-img" />
       
        </div>
        <div className='bottom'>
          <img src={logo2Img} alt="." className='logo' />
          <div className="t1">COPYRIGHT© 2017-2021 TRON FOUNDATION</div>
          {/* <div className="t1 t2">ICP No. 11017824-4</div>
          <div className="t1 t2">Copyright © 2006-2021  Sea Of Stars</div> */}
        </div>
      </div>

    </div>
  )
}

export default isMobile ? Page7Mobile : Page7