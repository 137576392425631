import React from "react";
import Lottie from 'lottie-react-web'
import '../../assets/scss/bottom.scss';

import setSailImg from '../../assets/images/page6/setting-sail.png'
import watchVideoImg from '../../assets/images/page6/watch-the-video.svg'
import mouse from '../../assets/json/mouse.json'

const Bottom = (props) => {

  const leftImg = props.leftImg || setSailImg;
  const leftTxt = props.leftTxt || 'Dream Building';
  const showVideoModal = props.showVideoModal;
  const isShowVideo = props.isShowVideo === undefined ? true : props.isShowVideo;
  const p = props.p || 2;

  const progress = ((p - 1) / 5) * 100;

  return (
    <div className="footer-wrap">
      {
        isShowVideo &&
        <div className="footer">
          <div className="left animate__animated animate__fadeIn animate__fast animate__delay-300ms">
            <img src={leftImg} alt="." />
            <span className="t1">{leftTxt}</span>
          </div>
          <div className="mid">
            <div className="ani">
              <div className="progress" style={{width: `${progress}%`}}></div>
            </div>
          </div>
          <div className="right animate__animated animate__fadeIn animate__fast animate__delay-300ms" onClick={showVideoModal}>
            <img src={watchVideoImg} alt="." />
            <span className="t2">Watch the video</span>
          </div>
        </div>
      }

      <div className="mouse-wrap">
        <Lottie
            options={{
              animationData: mouse
            }}
        />
        <span>Scroll to Explore</span>
      </div>
    </div>
  )
}

export default Bottom;
