import React, { useState, useEffect } from 'react';
import Bottom from '../common/Bottom';
import './index.scss'
import 'animate.css';
import Lottie from 'lottie-react-web'
import isMobile from '../../utils/isMobile';

import human from '../../assets/images/page2/human.png'
import star from '../../assets/images/page3/star.png'

import line from '../../assets/json/line2.json'
import bgMobile from '../../assets/images/page2/bg2-mobile.jpg'

const Page2 = ({show, transStart, swiper, showVideoModal}) => {

  const [isShowLine, setIsShowLine] = useState(false);


  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setIsShowLine(true);
      }, 2000);
    } else {
      isShowLine && setIsShowLine(false);
    }
  })

  return(
    <div className="page2-wrap">
      <div className="page2-content">
        <h2 className={`${show ? 'animate__animated animate__fadeInDown animate__faster' : ''} `}>
          <div></div>
          <div>pursuers look into the universe</div>
        </h2>
        <div className={`desc-wrap ${show ? 'animate__animated animate__fadeInDown animate__faster' : ''}`}>
          <h3 className={show ? 'animate__animated animate__fadeInDown animate__faster animate__delay-100ms' : ''}>
            - flying
          </h3>
          <p className={'desc-wrap-p1' + ' ' + (show ? 'animate__animated animate__fadeInDown animate__faster  animate__delay-300ms' : '')}>
          Justin Sun placed the winning bid for the first seat on New Shepard on June 13 for $28 million and wants to turn this "flying" opportunity into 6 slots on a launch/every spacecraft to inspire more people to participate in space exploration. This charity event will be called "Sea of Stars".
          </p>
        </div>
        <div className={`desc-wrap m-desc-wrap  ${show ? 'animate__animated animate__fadeInDown animate__faster' : ''}`}>
          <h3 className={show ? 'animate__animated animate__fadeInDown animate__faster  animate__delay-500ms' : ''}>
            - 2022
          </h3>
          <p className={'desc-wrap-p2' + ' ' + (show ? 'animate__animated animate__fadeInDown animate__faster  animate__delay-700ms' : '')}>
          The five lucky astronauts will be selected by Justin Sun. The event is expected to launch in the fourth quarter of 2022.
          </p>
        </div>
      </div>
      <div className={`human-wrap ${swiper && swiper.activeIndex === 1 ? 'human-scale-opacity' : ''} `}>
        <img src={human} alt="" />
      </div>

      <div className={`line-wrap ${show ? 'line-animation' : ''}`}>
        {
          isShowLine &&
          <Lottie
              options={{
                animationData: line,
                loop: false
              }}
          />
        }
      </div>

      <div className={`star-wrap ${swiper && swiper.activeIndex === 1 ? 'star-animation' : ''}`}>
        <img src={star} alt="" />
      </div>

      <div className="mask-wrap"></div>

      {
        show &&
        <Bottom leftTxt='Dream Building' showVideoModal={showVideoModal} p={2} />
      }
    </div>
  )
}

const Page2Mobile = (props) => {

  return (
    <div className='page-mobile page2-mobile'>

      <img src={bgMobile} alt='' className='bg-img' />

      <div className='txt-wrap'>
        <div className="title">pursuers look <br></br>into the universe</div>
        <div className='box-1'>
          <div className="sub">- flying</div>
          <div className="cont">Justin Sun placed the winning bid for the first seat on New Shepard on June 13 for $28 million and wants to turn this "flying" opportunity into 6 slots on a launch/every spacecraft to inspire more people to participate in space exploration. This charity event will be called "Sea of Stars". </div>
        </div>
        <div className='box-2'>
          <div className="sub">- 2022</div>
          <div className="cont">The five lucky astronauts will be selected by Justin Sun. The event is expected to launch in the fourth quarter of 2022. </div>
        </div>
      </div>

    </div>
  )
}

export default isMobile ? Page2Mobile : Page2