import React, {useState, useEffect} from "react";
import Bottom from "../common/Bottom";
import isMobile from '../../utils/isMobile';
import 'animate.css';
import './index.scss'

import swip1Img from '../../assets/images/page6/swiper1.jpg'
import swip2Img from '../../assets/images/page6/swiper2.jpg'
import swip3Img from '../../assets/images/page6/swiper3.jpg'
import bgMobile from '../../assets/images/page6/bg6-mobile.jpg'
import leftImg from '../../assets/images/page6/left-img.png'

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

import 'swiper/swiper.scss';

// import SwiperCore, {
//   Pagination,FreeMode
// } from 'swiper';

// SwiperCore.use([Pagination,FreeMode]);

// const animateCSS = (element, animation, prefix = 'animate__') =>
//   // We create a Promise and return it
//   new Promise((resolve, reject) => {
//     const animationName = `${prefix}${animation}`;
//     const node = document.querySelector(element);

//     node.classList.add(`${prefix}animated`, animationName);

//     // When the animation ends, we clean the classes and resolve the Promise
//     function handleAnimationEnd(event) {
//       event.stopPropagation();
//       node.classList.remove(`${prefix}animated`, animationName);
//       resolve('Animation ended');
//     }

//     node.addEventListener('animationend', handleAnimationEnd, {once: true});
// });

const Page6 = (props) => {

  const [activeIndex, setActiveIndex] = useState(1);
  const [myClassName, setmyClassName] = useState('animate__fadeInRight animate__delay-1s');
  const [swiper, setSwiper] = useState(null);

  let {show, showVideoModal} = props;

  useEffect(() => {
    if (props.show) {
      setActiveIndex(1);
      setmyClassName('animate__fadeInRight animate__delay-1s');
    }
  }, [props])

  let pageChange = (dir) => {
    if (dir < 0) {
      if (activeIndex > 1) {swiper.slideTo(activeIndex - 2)}
    } else {
      if (activeIndex < 3) {swiper.slideTo(activeIndex)}
    }
  }

  return (
    <div className="page6-wrap ">
      {
        show &&
        <>
          <div className="content">
            <div className="p1 animate__animated animate__fadeInDown  animate__faster animate__delay-300ms">
              Setting Sail Here I come
            </div>
            <div className="p2 animate__animated animate__fadeInDown animate__delay-1s animate__faster">
              <div className="t1">- EAGERNESS</div>
              <div className="t2">Whether it is blockchain or commercial aerospace, they are essentially exploring new unknown areas. To put it vividly, both are also building a larger network, one in the physical space and the other in the digital space. , Are all to allow human civilization to expand to a larger space.</div>
              <div className="t1">- INVENTIVE</div>
              <div className="t2">With the rapid development of commercial aeronautics and space travel, going into space could become a dream that ordinary people can fulfill in their lifetime. We are about to usher in the age of space travel. More people need to participate in this great journey. Much like Justin Sun's original intent to advance the construction of the Metaverse, commercial space travel is an important step in his path of exploration that is a step toward a more distant future.</div>
            </div>
          </div>

          <div className="content2">
            <div className="p1 animate__animated animate__fadeInRight animate__delay-1s animate__fast">blockchain & aerospace</div>
            <div className="p2 animate__animated animate__fadeInRight animate__delay-1s animate__fast"></div>
            <div className="p3">
              <div className={'t1 animate__animated animate__fast' + ' ' + myClassName} id='num-ani'>0{activeIndex}</div>
              <div className="t2 animate__animated animate__fadeInRight animate__delay-1s animate__fast">/03</div>
            </div>
            <div className='btn-wrap animate__animated animate__fadeInRight animate__delay-1s animate__fast'>
              <div className={`btn btn-left ${activeIndex <= 1 ? 'dis' : ''}`} onClick={() => {pageChange(-1)}}></div>
              <div className={`btn btn-right ${activeIndex >= 3 ? 'dis' : ''}`} onClick={() => {pageChange(1)}}></div>
            </div>
          </div>

          <div className="content3 animate__animated animate__fadeInRight animate__delay-1s animate__fast" >
            <Swiper
              // allowTouchMove={false}
              slidesPerView={'auto'}
              centeredSlides={true}
              spaceBetween={30}
              speed={500}
              className="mySwiper"
              onSlideChange={(swiper) => {
                setmyClassName('animate__fadeOutDown');
                setTimeout(() => {
                  setmyClassName('animate__fadeInDown');
                  setActiveIndex(swiper.activeIndex + 1);
                }, 300)
              }}
              onSwiper={(swiper) => {
                setSwiper(swiper);
              }}
            >
              <SwiperSlide className={activeIndex > 1 ? 'dis' : ''}><img src={swip1Img} alt='' /></SwiperSlide>
              <SwiperSlide className={activeIndex > 2 ? 'dis' : ''}><img src={swip2Img} alt='' /></SwiperSlide>
              <SwiperSlide className={activeIndex > 3 ? 'dis' : ''}><img src={swip3Img} alt='' /></SwiperSlide>
            </Swiper>
          </div>

          <Bottom leftImg={leftImg} leftTxt='Setting Sail' showVideoModal={showVideoModal} p={6} />
        </>
      }
    </div>
  )
}

const Page6Mobile = (props) => {

  const [activeIndex, setActiveIndex] = useState(1);

  return (
    <div className='page-mobile'>

      <img src={bgMobile} alt='' className='bg-img' />

      <div className='txt-wrap'>
        <div className="title">Setting Sail Here I come</div>
        <div className="sub">- EAGERNESS</div>
        <div className="cont">Whether it is blockchain or commercial aerospace, they are essentially exploring new unknown areas. To put it vividly, both are also building a larger network, one in the physical space and the other in the digital space. , Are all to allow human civilization to expand to a larger space.</div>
        <div className="sub">- INVENTIVE</div>
        <div className="cont">With the rapid development of commercial aeronautics and space travel, going into space could become a dream that ordinary people can fulfill in their lifetime. We are about to usher in the age of space travel. More people need to participate in this great journey. Much like Justin Sun's original intent to advance the construction of the Metaverse, commercial space travel is an important step in his path of exploration that is a step toward a more distant future.</div>
        <div className="swiper-title">
          <div className="txt">blockchain &</div>
          <div className="line"></div>
          <div className="num"><span>0{activeIndex}</span>/03</div>
        </div>
        <div className="swiper-title2">aerospace</div>
        <div className="swiper-outerbox">
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={15}
            centeredSlides={true}
            className="mySwiper"
            onSlideChange={(swiper) => {
              setActiveIndex(swiper.activeIndex + 1);
            }}
          >
            <SwiperSlide><img src={swip1Img} alt='' className="inner-img" /></SwiperSlide>
            <SwiperSlide><img src={swip2Img} alt='' className="inner-img" /></SwiperSlide>
            <SwiperSlide><img src={swip3Img} alt='' className="inner-img" /></SwiperSlide>
          </Swiper>
        </div>
      </div>

    </div>
  )
}

export default isMobile ? Page6Mobile : Page6