import React, {useEffect, useState, useImperativeHandle} from "react";
import Bottom from '../common/Bottom';
import Lottie from 'lottie-react-web'
import Modal from 'react-modal';
import isMobile from '../../utils/isMobile';

import './index.scss'
import 'animate.css';
import videoIco from '../../assets/images/video-icon.png'
import startIco from '../../assets/images/start.png';
import avator from '../../assets/images/avator.png'
import astronauts from '../../assets/images/astronauts.png'
import astronautsMobile from '../../assets/images/astronauts-mobile.png'
import humanM from '../../assets/images/modal-avatar.png'
import closeIco from '../../assets/images/icons/close.png'

import bgMobile from '../../assets/images/bg1-mobile.jpg'
import door from '../../assets/json/door.json'
import titleImg from '../../assets/images/title.png'
// import star from '../../assets/json/star.json'
import text from '../../assets/json/text.json'
require('../../utils/ct_effect.js')
require('../../utils/dissolve.js')


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 0,
    background: 'transparent'
  },
};

const customStylesMobile = {
  content: {
    ...customStyles.content,
    top: '45%',
    background: '#FFFFFF',
    overflow: 'visible'
  }
}

Modal.setAppElement('body');


const Home = ({show, isDissolve, activeIndex, showVideoModal, cRef}) => {

  useImperativeHandle(cRef, () => ({
    mSetModal: () => {
      setModal(true)
    }
  }))

  const effectHandle = () => {
    document.querySelector('.astronauts-wrap img').execEffect({
      animate: 'dissolve',	// 动画类型
      target: astronauts,//'http://y.gtimg.cn/music/common/upload/t_cm3_photo_publish/1432038769616126350.jpg',	// 替换的新图片
      size: 5,
      duration: 300,
    }).then(function() {
        // console.log('执行成功')
    })
  }

  useEffect(() => {
    if(isDissolve){
      // effectHandle()
    }
  }, [isDissolve])

  const [isOpen, setModal] = useState(false);


  return (
    <div className="home-wrap">
      <Modal
        isOpen={isOpen}
        style={customStyles}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setModal(false)}
      >
        <div className="close-wrap-human" onClick={() => setModal(false)}><img src={closeIco} alt="" /></div>
        <div className="modal-content human-modal">
          <div className="">
            <img src={humanM} alt="" />
          </div>
          <div className="">
            <div className="human-desc">
              <h3>Henry Hepburne</h3>
              <p>
              Whether it is blockchain or commercial aerospace, they are essentially exploring new unknown areas. To put it vividly, both are also building a larger network, one in the physical space and the other in the digital space. , Are all to allow human civilization to expand to a larger space
              </p>
            </div>
          </div>
        </div>
      </Modal>

      <div className="home-slogan-wrap">
        <h1 className={`${show ? 'animate__animated animate__fadeInDown animate__faster' : ''} `}>
          <Lottie
            options={{
              animationData: text,
              loop: false
            }}
          />
        </h1>
        <h3 className={`${show ? 'animate__animated animate__fadeInDown animate__faster animate__delay-300ms' : ''} `}>LAUNCHING SOON IN 2023</h3>
      </div>

      <div className="bottom-wrap-index">
        {/* <div className="left" onClick={() => setModal(true)}>
          <div className="line"></div>
          <div className="human-name">
            <img src={avator} alt="" />
            <span>Justin Sun‘s space journey</span>
          </div>
        </div> */}
        <div className="right" onClick={showVideoModal}>
          <img src={videoIco} alt=""  className="bg-img" />
          <img src={startIco} alt=""  className="start-img" />
        </div>
      </div>

      {/* <div className="stars-wrap">
        <Lottie
          options={{
            animationData: star
          }}
        />
      </div> */}

      <div className="door-wrap">
        <Lottie
          options={{
            animationData: door
          }}
        />
      </div>

      {/* <div className={`astronauts-wrap-gif `}>
        <img className={`${isDissolve ? 'astronauts-wrap-ani' : ''}`} src={astronauts} alt="" />
        <img src={humanA} className={`${isDissolve ? 'visible' : ''}`} alt="" />
      </div> */}

      <div className={`astronauts-wrap ${show ? "animate__animated animate__fadeIn animate__slow animate__delay-1s" : ""}`}>
        <img src={astronauts} className={`${isDissolve ? 'astronauts-wrap-ani' : ''}`} alt="" />
      </div>

      <div className="mask-wrap"></div>

      {
        show &&
        <Bottom isShowVideo={false} showVideoModal={showVideoModal} />
      }

    </div>
  )
}

const HomeMobile = ({show, isDissolve, activeIndex, showVideoModal, cRef}) => {

  useImperativeHandle(cRef, () => ({
    mSetModal: () => {
      setModal(true)
    }
  }))

  const [isOpen, setModal] = useState(false);

  return (
    <div className="home-mobile">

      <Modal
        isOpen={isOpen}
        style={customStylesMobile}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setModal(false)}
      >
        <div className="close-wrap-mobile" onClick={() => setModal(false)}></div>
        <div className="modal-content human-modal">
          <div className="sun-mobile">
            <img src={humanM} alt="" />
          </div>
          <div className="">
            <div className="human-desc">
              <h3>Henry Hepburne</h3>
              <p>
              Whether it is blockchain or commercial aerospace, they are essentially exploring new unknown areas. To put it vividly, both are also building a larger network, one in the physical space and the other in the digital space. , Are all to allow human civilization to expand to a larger space
              </p>
            </div>
          </div>
        </div>
      </Modal>

      <img src={bgMobile} alt='' className='bg-img' />

      <div className="home-slogan-wrap">
        <img src={titleImg} alt='' className="title-img" />
        <h3>LAUNCHING SOON IN 2023</h3>
      </div>

      <div className="door-wrap">
        <Lottie
          options={{
            animationData: door
          }}
        />
      </div>

      <div className="astronauts-wrap animate__animated animate__fadeIn animate__slow animate__delay-1s">
        <img src={astronautsMobile} alt="" />
      </div>

      <div className="mask-wrap"></div>
    </div>
  )
}

export default isMobile ? HomeMobile : Home