import React, {useState, useEffect} from "react";
import Bottom from '../common/Bottom';
import isMobile from '../../utils/isMobile';
import Lottie from 'lottie-react-web'
import 'animate.css';
import './index.scss'

import spaceManImg from '../../assets/images/page4/space-man.png'
import line from '../../assets/json/line4.json'
import bgMobile from '../../assets/images/page4/bg4-mobile.jpg'
import leftImg from '../../assets/images/page4/left-img.png'

const Page4 = (props) => {

  const [isShowLine, setIsShowLine] = useState(false);

  let {show, showVideoModal} = props;

  useEffect(() => {
    if (props.show) {
      setTimeout(() => {
        setIsShowLine(true);
      }, 1500);
    } else {
      isShowLine && setIsShowLine(false);
    }
  }, [props])

  return (
    <div className="page4-wrap ">
      <img src={spaceManImg} alt='' style={{display: 'none'}} />
      {
        show &&
        <>
          <div className='lott-wrap'>
            <img src={spaceManImg} className='space-man' alt='' />
            {
              isShowLine && <div className='inner-box'><Lottie options={{animationData: line, loop: false}} /></div>
            }
          </div>

          <div className="content">
            <div className="p1 animate__animated animate__fadeInDown animate__faster animate__delay-300ms">
              the great journey of mankind
            </div>
            <div className="p2">
              <div className="t0 animate__animated animate__fadeInDown animate__delay-400ms">Looking at the stars and exploring the universe is the common aspiration of all humanity.</div>
              <div className="t1 animate__animated animate__fadeInDown animate__delay-400ms">- SPACE</div>
              <div className="t2 animate__animated animate__fadeInDown animate__delay-500ms">If you look at all of human civilization, humanity has been curious about the universe since ancient times. Humanity is eager to understand the universe, eager to fly into space, eager to explore unknown territories, it is this "eagerness" that drives the development of human civilization. In the long history of space exploration, countless people with lofty ideas have laid down their lives, and many have even sacrificed their precious lives... But the exploration of space has never ceased.</div>

            </div>
          </div>

          <div className="content2">
            <div className="p2">
              <div className="t1 animate__animated animate__fadeInDown animate__delay-400ms">- EARTH</div>
              <div className="t2 animate__animated animate__fadeInDown animate__delay-500ms">Moreover, the exploration of "outer space" has led to a transformation of life "on the ground" that has implications for the well-being of humanity. In areas such as satellites, communications, medical treatment, crisis management, and entertainment, the function and practical value of new technologies and products offered by space exploration are of great importance. Commercial spaceflight is currently at an important historical crossroads. Talent, technologies, and markets are now available. What is needed is a market-oriented and inventive environment to create an effective mechanism to promote the sector and allow it to consciously evolve as a whole. </div>
            </div>
          </div>

          <Bottom leftImg={leftImg} leftTxt='Exploration' showVideoModal={showVideoModal} p={4} />
        </>
      }
    </div>
  )
}

const Page4Mobile = (props) => {

  return (
    <div className='page-mobile page4-mobile'>

      <img src={bgMobile} alt='' className='bg-img' />

      <div className='txt-wrap'>
        <div className="title">the great journey of mankind</div>
        <div className="cont1">Looking at the stars and exploring the universe is the common aspiration of all humanity.</div>
        <div className="sub">- SPACE</div>
        <div className="cont">If you look at all of human civilization, humanity has been curious about the universe since ancient times. Humanity is eager to understand the universe, eager to fly into space, eager to explore unknown territories, it is this "eagerness" that drives the development of human civilization. In the long history of space exploration, countless people with lofty ideas have laid down their lives, and many have even sacrificed their precious lives... But the exploration of space has never ceased.</div>
        <div className="sub">- EARTH</div>
        <div className="cont">Moreover, the exploration of "outer space" has led to a transformation of life "on the ground" that has implications for the well-being of humanity. In areas such as satellites, communications, medical treatment, crisis management, and entertainment, the function and practical value of new technologies and products offered by space exploration are of great importance. Commercial spaceflight is currently at an important historical crossroads. Talent, technologies, and markets are now available. What is needed is a market-oriented and inventive environment to create an effective mechanism to promote the sector and allow it to consciously evolve as a whole. </div>
      </div>

    </div>
  )
}

export default isMobile ? Page4Mobile : Page4