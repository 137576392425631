import React, { useState, useEffect, useRef } from 'react';
import isMobile, {isIphone} from './utils/isMobile';
// import logo from './logo.svg';
import './assets/scss/main.scss'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import Modal from 'react-modal';

import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/effect-fade/effect-fade.scss';
import 'swiper/modules/effect-creative/effect-creative.scss';
import 'swiper/modules/parallax/parallax.scss';

import Home from './Components/Home';
import Header from './Components/common/Header';
import Page2 from './Components/Page2';
import Page3 from './Components/Page3';
import Page4 from './Components/Page4';
import Page5 from './Components/Page5';
import Page6 from './Components/Page6';
import Page7 from './Components/Page7';

import closeIco from './assets/images/icons/close-mobile.png'
import videoIco from './assets/images/video-icon.png'
import avator from './assets/images/avator.png'
import startIco from './assets/images/start.png';

import videoSource from './assets/video/v.mp4';

// import Swiper core and required modules
import SwiperCore, {
  EffectFade,
  Mousewheel,
  EffectCreative,
  Parallax
} from 'swiper';

// install Swiper modules
SwiperCore.use([EffectFade, Mousewheel, EffectCreative, Parallax]);

const commonStyles = {
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)',
  background: 'transparent',
  border: 'none',
  overflow: 'visible',
}

const customStyles = {
  content: {
    ...commonStyles,
    width: '1280px',
    height: '723px'
  },
};

let screenHeight = document.documentElement.clientHeight || document.body.clientHeight;
if (screenHeight < 800) {
  customStyles.content.maxWidth = '80%';
  customStyles.content.maxHeight = '70%';
}

const customStylesMobile = {
  content: {
    ...commonStyles,
    top: '40%'
  },
};

let timer = null;

function App() {
  const [activeIndex, setActiveIndex] = useState(0)
  const [isDissolve, setDissolve] = useState(false)

  const [swiper, setSwiper] = useState(null);

  const [isOpen, setModal] = useState(false);

  const childRef = useRef();
  const updateChildState = () => {
    childRef.current.mSetModal();
  };

  const headerRef = useRef();
  const toggleAudioPlayStatus = (e, handControl) => {
    headerRef.current.togglePlayStatus(e, handControl);
  }

  const video = useRef(null);

  const [isShowBottom, setIsShowBottom] = useState(true);

  const showModel = (e) => {
    e.stopPropagation && e.stopPropagation();
    toggleAudioPlayStatus(e, false);
    setModal(true);
    setTimeout(() => {
      video.current.play();

      // ios video调用原生播放器退出全屏事件
      if (isIphone) {
        video.current.addEventListener('webkitendfullscreen', () => {
          setModal(false);
        })
      }
    }, 200);
  }
  const hideModel = () => {
    toggleAudioPlayStatus({}, true);
    setModal(false);
    video.current.pause();
  }

  useEffect(() => {
    if (isMobile) {
      document.addEventListener('scroll', () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          let screenHeight = document.documentElement.clientHeight || document.body.clientHeight;
          let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
          let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
          if (scrollTop + screenHeight + screenHeight >= scrollHeight) {
            setIsShowBottom(false)
          } else {
            setIsShowBottom(true)
          }
        }, 50)
      })
    }
  }, [])

  return (
    <>
    {
      !isMobile ?
      <div className="main">
        <Modal
          isOpen={isOpen}
          style={customStyles}
          shouldCloseOnOverlayClick={true}
          onRequestClose={hideModel}
        >
          <div className="close-wrap" onClick={hideModel}><img src={closeIco} alt="" /></div>
          <div className="modal-content video-modal">
            <video src={videoSource} controls ref={video} width='1280' height='720' ></video>
          </div>
        </Modal>
        <Header cRef={headerRef} />
        <Swiper
          className="mySwiper"
          speed={1000}
          effect={'fade'}
          onTransitionStart={(swiper) => {
            setActiveIndex(undefined)
            setDissolve(swiper.previousIndex == 0)
          }}
          onTransitionEnd={(swiper) => {
            setActiveIndex(swiper.activeIndex)
          }}
          onSwiper={(swiper) => {
            setSwiper(swiper);
          }}
          direction={'vertical'}
          // parallax={true}
          mousewheel={true}
          simulateTouch={false}
          >
          <SwiperSlide>
            <Home show={activeIndex === 0}  activeIndex={activeIndex} isDissolve={isDissolve} showVideoModal={showModel}/>
          </SwiperSlide>
          <SwiperSlide>
            <Page2 show={activeIndex === 1} swiper={swiper}  showVideoModal={showModel}/>
          </SwiperSlide>
          <SwiperSlide>
            <Page3 show={activeIndex === 2} swiper={swiper} showVideoModal={showModel}/>
          </SwiperSlide>
          <SwiperSlide>
            <Page4 show={activeIndex === 3} showVideoModal={showModel}/>
          </SwiperSlide>
          <SwiperSlide>
            <Page5 show={activeIndex === 4} showVideoModal={showModel}/>
          </SwiperSlide>
          <SwiperSlide>
            <Page6 show={activeIndex === 5} showVideoModal={showModel}/>
          </SwiperSlide>
          <SwiperSlide>
            <Page7 show={activeIndex === 6} swiperRef={swiper}  showVideoModal={showModel}/>
          </SwiperSlide>
        </Swiper>
      </div>
      :
      <section id="mobileWrap">
        <Modal
          isOpen={isOpen}
          style={customStylesMobile}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => setModal(false)}
          portalClassName="ReactModalPortal video-modal-portal"
        >
          <div className="close-wrap-mobile" onClick={() => setModal(false)}></div>
          <div className="modal-content video-modal">
            <video
            src={videoSource}
            controls
            ref={video}
            playsInline={true}
            webkitplaysinline='true'
          ></video>
          </div>
        </Modal>
        <Header cRef={headerRef} />
        <Home cRef={childRef} showVideoModal={showModel} />
        <Page2/>
        <Page3/>
        <Page4/>
        <Page5/>
        <Page6/>
        <Page7/>
        <div className={`mobile-bottom-wrap ${isShowBottom ? 'in-ani' : 'out-ani'}`}>
          {/* <div className="mobile-human-name" onClick={() => updateChildState()}>
            <img src={avator} alt="" />
            <span>Justin Sun‘s space journey</span>
          </div> */}
          <div className="right" onClick={showModel}>
            <img src={videoIco} alt=""  className="bg-img" />
            <img src={startIco} alt=""  className="start-img" />
          </div>
        </div>
      </section>
     }
    </>
  );
}

// function AppMobile () {
//   return (
//     <section>
//       <Header />
//       <Home showVideoModal={() => setModal(true)} />
//       <Page2/>
//       <Page3/>
//       <Page4/>
//       <Page5/>
//       <Page6/>
//       <Page7/>
//     </section>
//   )
// }

export default App;
