import React from 'react';
import Bottom from '../common/Bottom';
import './index.scss'
import 'animate.css';
import isMobile from '../../utils/isMobile';

import star from '../../assets/images/page3/star.png'
import human from '../../assets/images/page3/human.png'
import humanM from '../../assets/images/page3/human-m.png'

import leftImg from '../../assets/images/page3/left-img.png'
import bgMobile from '../../assets/images/page3/bg3-mobile.jpg'

const Page3 = ({show, swiper, showVideoModal}) => {
  return(
    <div className="page3-wrap">

      <div className={`star-wrap ${swiper && swiper.activeIndex === 2 ? 'star-animation' : ''}`}>
        <img src={star} alt="" />
      </div>
      <div className={`galaxy-wrap`}>
        <h2 className={`${show ? 'animate__animated fade-down-custom' : ''} `}>
          <div></div>
          <div>Look at {!isMobile && <br />}the stars</div>
        </h2>
        <div className="galaxy-content">
          {!isMobile && <>
          <div className={`star star1 ${swiper && swiper.activeIndex === 2 ? 'star-move1' : ''}`}></div>
          <div className={`star star2 ${swiper && swiper.activeIndex === 2 ? 'star-move2' : ''}`}></div>
          <div className={`star star3 ${swiper && swiper.activeIndex === 2 ? 'star-move3' : ''}`}></div>
          </>}
          <div className={`galaxy-desc galaxy-desc1 ${show ? 'galaxy-desc1-ani' : ''}`}>
            <h3>Justin Sun</h3>
            <p>Justin Sun, who was born in 1990 in Xining City, Qinghai Province, dreamed of being space as a child and kept pushing his limits. It took him a year to go from a middle school student to a top student at Peking University. He not only achieves excellent academic results, but also actively cares about society and fulfils the mission of being a student of Peking University. He graduated early from Peking University and continued his educational journey at the University of Pennsylvania, which broadened his view to a global level.</p>
          </div>
          <div className={`galaxy-desc galaxy-desc2 ${show ? 'galaxy-desc2-ani' : ''}`}>
            <h3>TRON</h3>
            <p>In 2012, Justin Sun introduced blockchain as a "value network" in China and became an early evangelist and practitioner of Chinese blockchain. In 2017, he founded TRON. It currently has 62 million registered users, making it one of the three largest public chains in the world.</p>
          </div>
          <div className={`galaxy-desc galaxy-desc3 ${show ? 'galaxy-desc3-ani' : ''}`}>
            <h3>Space Tour</h3>
            <p>Justin Sun is not only a successful young entrepreneur, but also advocates for the development of cutting-edge technology and participated in the charity auction of Blue Origin Space Tour, for example. With his actions, he tries to inspire more people to join this great adventure. </p>
          </div>
        </div>
      </div>
      <div className="human-wrap">
        <img src={isMobile ? humanM : human} alt="" />
      </div>

      <div className="mask-wrap"></div>

      {
        show &&
        <Bottom leftImg={leftImg} leftTxt='Look at the stars ' showVideoModal={showVideoModal} p={3}  />
      }

    </div>
  )
}

const Page3Mobile = (props) => {

  return (
    <div className='page-mobile page3-mobile'>

      <img src={bgMobile} alt='' className='bg-img' />

      <div className='txt-wrap'>
        <div className="title">Look at the stars</div>
        <div className="sub">- Justin Sun</div>
        <div className="cont">Justin Sun, who was born in 1990 in Xining City, Qinghai Province, dreamed of being space as a child and kept pushing his limits. It took him a year to go from a middle school student to a top student at Peking University. He not only achieves excellent academic results, but also actively cares about society and fulfils the mission of being a student of Peking University. He graduated early from Peking University and continued his educational journey at the University of Pennsylvania, which broadened his view to a global level.</div>
        <div className="sub">- TRON</div>
        <div className="cont">In 2012, Justin Sun introduced blockchain as a "value network" in China and became an early evangelist and practitioner of Chinese blockchain. In 2017, he founded TRON. It currently has 62 million registered users, making it one of the three largest public chains in the world.</div>
        <div className="sub">- Space Tour</div>
        <div className="cont">Justin Sun is not only a successful young entrepreneur, but also advocates for the development of cutting-edge technology and participated in the charity auction of Blue Origin Space Tour, for example. With his actions, he tries to inspire more people to join this great adventure. </div>
      </div>

    </div>
  )
}

export default isMobile ? Page3Mobile : Page3